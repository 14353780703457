import Header from '../components/Header';
import Footer from '../components/Footer';
import GoTopButton from '../components/generic/GoTopButton';
import DetailedServices from '../components/DetailedServices';

export default function Contact()  {
  return (
    <div className="min-h-full">
      <Header />
      <main>
        <DetailedServices />
        <Footer />
        <GoTopButton />
      </main>
    </div>
  );
}