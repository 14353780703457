import { ArrowPathIcon, CloudArrowUpIcon, FingerPrintIcon, CubeIcon, LockClosedIcon, AdjustmentsHorizontalIcon, DocumentChartBarIcon } from '@heroicons/react/24/outline'

const features = [
  {
    name: "UX/UI Design",
    description:
      "Through a clear understanding of your brands personas and broader value proposition, NA Digital Services marries memorable visual identity with functional user journeys for World Class experiences that delight and engage users.",
    icon: CloudArrowUpIcon,
  },
  {
    name: "Web Development",
    description:
      "Our web development services deploy next-gen technologies that collaborates rich UI/UX designs and right innovative technologies to create a seamless harmony that drives high ROI and engage more customers to your business.",
    icon: LockClosedIcon,
  },
  {
    name: "Ecommerce Development",
    description:
      "We create ecommerce solutions for B2C, B2B and marketplace business models. We can smoothly integrate an ecommerce component into your operating web app or launch a new ecommerce presence.",
    icon: ArrowPathIcon,
  },
  {
    name: "Mobile Development",
    description:
      "Leverage our decades of experience in mobile app development, and hire us to build robust, scalable,and user-friendly mobile apps on both Android and iOS platforms for your business.",
    icon: FingerPrintIcon,
  },
  // {
  //   name: "Quality Management",
  //   description:
  //     "Excellent quality software and web application management fulfills your requirements.",
  //   icon: DocumentChartBarIcon,
  // },
  {
    name: "SEO",
    description:
      "We are dedicated to grow sales with ROI through high quality ethical white.",
    icon: AdjustmentsHorizontalIcon,
  },
  {
    name: "Digital Marketing",
    description:
      "We are providing panoramic digital marketing services to our clients to take their business to next-level digitally.",
    icon: CubeIcon,
  },
];

export default function Services() {
  return (
    <div className="bg-white py-24 sm:py-32" id="services">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Our Services
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            We Offer a Wide Variety of IT Services.
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-16">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-primary">
                    <feature.icon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
        <div className="mx-auto max-w-2xl lg:text-center mt-10">
          <a
            href="/services"
            className="rounded-md bg-primary px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
          >
            View All Services
          </a>
        </div>
      </div>
    </div>
  );
}
