const projects = [
  {
    name: "Wisdome",
    role: "WISDOME is the MULTI AWARD WINNING Content and Engagement Platform",
    imageUrl: "project-wisdome.webp",
  },
  {
    name: "CUT",
    role: "All-In-One Travel App",
    imageUrl: "project-1.webp",
  },
  {
    name: "Newtracs",
    role: "Australia's largest track 4x4 conditions database.",
    imageUrl: "project-2.webp",
  },
  {
    name: "The Beauty Club",
    role: "The first of its kind in the online beauty industry",
    imageUrl: "project-3.webp",
  },
  {
    name: "Restaurant Site",
    role: "Resturant site for a great client",
    imageUrl: "project-4.webp",
  },
];

export default function ProjectSection() {
    return (
        <div className="bg-white py-12 md:py-16 lg:py-20" id="projects">
            <div className="mx-auto grid max-w-7xl grid-cols-1 gap-x-8 gap-y-20 px-6 lg:px-8 xl:grid-cols-3">
                <div className="mx-auto max-w-2xl lg:mx-0">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Our Projects</h2>
                    <p className="mt-6 text-lg leading-8 text-gray-600">
                        Our top Completed projects with maximum clients satistaction.
                    </p>
                </div>
                <ul
                    role="list"
                    className="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-20 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:gap-x-8 xl:col-span-2"
                >
                    {projects.map((person) => (
                        <li key={person.name}>
                            <img className="aspect-[3/2] w-full rounded-2xl object-cover" src={person.imageUrl} alt="" />
                            <h3 className="mt-6 text-lg font-semibold leading-8 text-gray-900">{person.name}</h3>
                            <p className="text-base leading-7 text-gray-600">{person.role}</p>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}
