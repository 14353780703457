const navigation = {
    main: [
        { name: 'About', href: '#about' },
        { name: 'Services', href: '#services' },
        { name: 'Projects', href: '#projects' },
        { name: 'Stats', href: '#stats' },
        { name: 'Contact Us', href: '/contact' },
    ],
}

export default function Footer() {
    return (
        <footer className="bg-primary">

            <div className="mx-auto max-w-7xl overflow-hidden px-6 py-20 sm:py-24 lg:px-8">
                <div className="grid grid-cols-6 items-center">
                    <div className="">
                        <img
                            className="h-30 w-auto"
                            src="/logo-nad.png"
                            alt="NA Digital Services"
                        />
                    </div>
                    <div className="col-span-5">
                        <nav className="-mb-6 columns-2 sm:flex sm:justify-center sm:space-x-12" aria-label="Footer">
                            {navigation.main.map((item) => (
                                <div key={item.name} className="pb-6">
                                    <a href={item.href} className="text-sm leading-6 text-gray-50 hover:text-gray-200">
                                        {item.name}
                                    </a>
                                </div>
                            ))}
                        </nav>
                        {/* <div className="mt-10 flex justify-center space-x-10">
            {navigation.social.map((item) => (
              <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-500">
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-6 w-6" aria-hidden="true" />
              </a>
            ))}
          </div> */}
                        <p className="mt-10 text-center text-xs leading-5 text-gray-50">
                            &copy; 2024 NA Digital Services, Pvt Ltd. All rights reserved.
                        </p>
                    </div>
                </div>

            </div>
        </footer>
    )
}
